import React from "react";
import insuranceAprilLogo  from "../assets/images/april-international-logo-vector-2024.svg";
import insuranceAllianzLogo  from "../assets/images/insu-allianz.svg";
import insuranceMSHLogo  from "../assets/images/insu-msh.svg";
import insuranceHennerLogo  from "../assets/images/insu-henner.svg";
import insuranceCignaLogo  from "../assets/images/cigna-logo-vector.svg";
import insuranceLibertyLogo  from "../assets/images/liberty.png";
import insurancePTILogo  from "../assets/images/pti.png";
import insuranceAssurTravelLogo  from "../assets/images/swiss-life.svg"
import Tooltip from "../components/GlobalComponents/Tooltip/Tooltip";
import {Trans} from 'react-i18next';
import {changeCurrency} from "../actions/actions";

export function renderTypeOfCoverageList(coverage_types_included, insurance_company, plan_id) {

    switch (coverage_types_included) {
        case 'H':
            return (
                <ul>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_hospitalization"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_healthcare"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_optical"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_maternity"></Trans></li>
                </ul>
            );
        case 'H+RC':
            return (
                <ul>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_hospitalization"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_healthcare"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_optical"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_maternity"></Trans></li>
                </ul>
            );
        case 'H+M':
            return (
                <ul>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_hospitalization"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_healthcare"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_optical"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span>
                        <Trans i18nKey="coveregePage_filter_needed_maternity"/>
                        {(insurance_company === 'Allianz')
                            ?
                                <Tooltip
                                    tooltipId={`coverage_mat_${plan_id}`}
                                    tooltipType="light"
                                    tooltipEffect="solid"
                                    tooltipIconSize={14}
                                    tooltipIconTopPosition={2}
                                    tooltipIconOffset={5}
                                    tooltipContent="
                                       Chez Allianz, l’option maternité est incluse de base avec
                                       l’hospitalisation. Toutes les offres comprennent
                                       l’option maternité, sans surcoût."
                                />
                            : null }
                    </li>
                </ul>
            );
        case 'H+RC+OD':
            return (
                <ul>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_hospitalization"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_healthcare"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_optical"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_maternity"></Trans></li>
                </ul>
            );
        case 'H+RC+M':
            return (
                <ul>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_hospitalization"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_healthcare"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_optical"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span>
                        <Trans i18nKey="coveregePage_filter_needed_maternity"/>
                        {(insurance_company === 'Allianz')
                            ?
                            <Tooltip
                                tooltipId={`coverage_mat_${plan_id}`}
                                tooltipType="light"
                                tooltipEffect="solid"
                                tooltipIconSize={14}
                                tooltipIconTopPosition={2}
                                tooltipIconOffset={5}
                                tooltipContent="
                                       Chez Allianz, l’option maternité est incluse de base avec
                                       l’hospitalisation. Toutes les offres comprennent
                                       l’option maternité, sans surcoût."
                            />
                            : null }
                    </li>
                </ul>
            );
        case 'H+RC+OD+M':
            return (
                <ul>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_hospitalization"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_healthcare"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_optical"></Trans></li>
                    <li className="included-coverage"><span className="icon-check-mark"></span>
                        <Trans i18nKey="coveregePage_filter_needed_maternity"/>
                        {(insurance_company === 'Allianz')
                            ?
                            <Tooltip
                                tooltipId={`coverage_mat_${plan_id}`}
                                tooltipType="light"
                                tooltipEffect="solid"
                                tooltipIconSize={14}
                                tooltipIconTopPosition={2}
                                tooltipIconOffset={5}
                                tooltipContent="
                                       Chez Allianz, l’option maternité est incluse de base avec
                                       l’hospitalisation. Toutes les offres comprennent
                                       l’option maternité, sans surcoût."
                            />
                            : null }
                    </li>
                </ul>
            );
        default:
            return (
                <ul>
                    <li className="included-coverage"><span className="icon-check-mark"></span><Trans i18nKey="coveregePage_filter_needed_hospitalization"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_healthcare"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_optical"></Trans></li>
                    <li className="not-included-coverage"><span className="icon-close"></span><Trans i18nKey="coveregePage_filter_needed_maternity"></Trans></li>
                </ul>
            );
    }

}


export function renderGuaranteeLevelNameTag(guarantee_level_EA_displayed) {

    switch (guarantee_level_EA_displayed) {
        case 'Basic':
            return (
                <div className="planGuaranteeLevelName" style={{backgroundColor: "#D6D6D6"}}><span className="icon-star-one"></span> { guarantee_level_EA_displayed }</div>
            );
        case 'Comfort':
            return (
                <div className="planGuaranteeLevelName" style={{backgroundColor: "#D7CBA4"}}><span className="icon-star-two"></span> { guarantee_level_EA_displayed }</div>
            );
        case 'VIP':
            return (
                <div className="planGuaranteeLevelName" style={{backgroundColor: "#FFCB12"}}><span className="icon-star-three"></span> { guarantee_level_EA_displayed }</div>
            );
        default:
            return null;
    }

}


export function renderInsuranceCompanyLogo(insurance_company) {

    switch (insurance_company) {
        case 'April':
            return insuranceAprilLogo;

        case 'Allianz':
            return insuranceAllianzLogo;

        case 'MSH':
            return insuranceMSHLogo;

        case 'Henner':
            return insuranceHennerLogo;

        case 'Liberty':
            return insuranceLibertyLogo;

        case 'PTI':
            return insurancePTILogo;

        case 'Cigna':
            return insuranceCignaLogo;

        case 'Assur Travel':
            return insuranceAssurTravelLogo;

        default:
            return insuranceAprilLogo;
    }

}


export function generateFormattedPrice(price_value, currency) {
    let formatter;

    if (currency === 'USD') {
        formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
        });
    } else {
        formatter = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: currency,
        });
    }


    return formatter.format(price_value);

}


export function embedHubSpotInitScripts() {

    // TODO: ця вся реалізація через iframe не камельфо, можливо потім через API зробити

    setTimeout(function () {

        // видаляємо старий script хабспота, щоб при повторному відкриванні, можна було б його знову ініціалізувати
        let hubspot_meetings_iframe_dom = document.getElementById('hubspot_meetings_iframe');

        if (hubspot_meetings_iframe_dom ) {

            hubspot_meetings_iframe_dom.parentNode.removeChild(hubspot_meetings_iframe_dom);

        }

        // вставляємо script хабспотам щоб ініціалізувати iframe
        const script = document.createElement('script');
        script.id = 'hubspot_meetings_iframe';
        script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        document.body.appendChild(script);

    }, 100);

}


export function textInputValidation(string_from_input) {

    if ( string_from_input && string_from_input.trim().length > 0 ) {

        return true;

    } else {

        return false;

    }

}


export function emailInputValidation(string_from_input) {

    let emailReg = /^(([^<>() [\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( emailReg.test(String(string_from_input).toLowerCase()) ) {

        return true;

    } else {

        return false;

    }

}


export function getAdultsAmount(insurees_data, data_type) {

    if ( data_type === 'solid_array' ) {

        let adults_count = 0;

        if ( insurees_data ) {

            insurees_data.forEach(item_insuree => {

                if (item_insuree.age > 26) {

                    adults_count++;

                }

            });

        }

        return adults_count;

    } else {

        let adults_count = 0;
        let dependents_array = insurees_data.dependents_array;
        let insuree_object_age = insurees_data.insuree_object.age;

        if ( insuree_object_age > 26 ) {

            adults_count++;

        }

        if ( dependents_array ) {

            dependents_array.forEach(item_dependent => {

                if( item_dependent.age > 26 ) {

                    adults_count++;

                }

            });

        }

        return adults_count;

    }

}


export function isJson(str) {

    try {

        JSON.parse(str);

    } catch (e) {

        return false;

    }

    return true;

}


export function detectDollarCountry(currency){
    let flag;
    switch (currency){
        case "USD":
            flag = "$";
            break;
        default:
            flag = '€';
            break;
    }
    return flag
}

export function detectCurrency(country){

    console.log("country",country)
    let flag;
    switch (country){
        case "États-Unis d’Amérique":
        case "Thaïlande":
        case "Vietnam":
        case "Singapour":
        case "Hong Kong":
        case "Chine":
        case "Japon":
        case "Australie":
        case "Nouvelle-Zélande":
        case "Corée du Sud":
        case "Taïwan":
        case "Malaisie":
        case "Laos":
        case "Cambodge":
        case "Myanmar":
            flag = changeCurrency("USD");
            break;
        default:
            flag = changeCurrency('EUR');
            break;
    }
    return flag;
}






function lookup_in_April_deductible_table(coverage_types_included, destination_zone, deductible_value){
    //if deductible is 0 then there's no need to transform anything, it'll stay 0, exit the function
    if (deductible_value === 0) {
        return 0;
    }
    let deductibleSteps = {
        "500": 0,
        "1000": 1,
        "2500": 2,
        "5000": 3
    };
    let deductibleCoefficientsWhenValue500 = {};

    switch(coverage_types_included) {
        case 'H': {
            //we're in the first square
            deductibleCoefficientsWhenValue500 = {
                'Zone 5': '75',
                'Zone 4B': '80',
                'Zone 4': '80',
                'Zone 3': '80',
                'Zone 2': '83',
                'Zone 1': '85',
                'Zone 0': '95',
            };

            break;
        }

        case 'H+RC':
        case 'H+RC+M': {
            //second square
            deductibleCoefficientsWhenValue500 = {
                'Zone 5': '80',
                'Zone 4B': '85',
                'Zone 4': '85',
                'Zone 3': '85',
                'Zone 2': '88',
                'Zone 1': '90',
                'Zone 0': '97',
            };
            break;
        }

        case 'H+RC+OD':
        case 'H+RC+OD+M': {
            //third square
            deductibleCoefficientsWhenValue500 = {
                'Zone 5': '85',
                'Zone 4B': '90',
                'Zone 4': '90',
                'Zone 3': '90',
                'Zone 2': '93',
                'Zone 1': '95',
                'Zone 0': '98',
            };
            break;
        }

        default:
            return null
    }

    /**
     * the formula means: take the highest coefficient for 500 and detract 5% each time you move right in the table
     * this way for e.g. deductible 2500 and zone 3 HOS coverage type, starting coefficient will be 80,
     * then we detract 5% 2 times - go two steps to the right in the table to reach coefficient for 2500 which is two steps from 500
     */
    let finalDeductibleCoefficient =
        deductibleCoefficientsWhenValue500[destination_zone] - 5 * deductibleSteps[deductible_value];
    console.log("April deductible returning coefficient: " + finalDeductibleCoefficient);
    return finalDeductibleCoefficient;
}

function lookup_in_April_Asian_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, paid_insurees_number) {
    function asian_loop(deductible){
        for (var i = 0; i < paid_insurees_number; i++){

            if ( spreadsheet_rows[i] ) {

                price += spreadsheet_rows[i][deductible];

            }

        }
    }
    if (destination_country === "Thaïlande") {
        destination_country = "Thailand";
    }
    // console.log(destination_country)
    let price = 0;
    // console.log(destination_country)
    switch (destination_country){
        case "Hong Kong":
            switch (deductible_value){
                case 1500:
                    asian_loop("Deductible 1500")
                    break;
                case 3000:
                    asian_loop("Deductible 3000")
                    break;
                case 5000:
                    asian_loop("Deductible 5000")
                    break;
                case 10000:
                    asian_loop("Deductible 10000")
                    break;
                default:
                    price = null;
            }
            break;
        case "Vietnam":
            switch (deductible_value){
                case 500:
                    asian_loop("Deductible 500")
                    break;
                case 1000:
                    asian_loop("Deductible 1000")
                    break;
                case 2500:
                    asian_loop("Deductible 2500")
                    break;
                case 5000:
                    asian_loop("Deductible 5000")
                    break;
                case 10000:
                    asian_loop("Deductible 10000")
                    break;
                default:
                    price = null;
            }
            break
        case "Thailand":
            switch (deductible_value){
                case 500:
                    asian_loop("Deductible 500")
                    break;
                case 1000:
                    asian_loop("Deductible 1000")
                    break;
                case 2500:
                    asian_loop("Deductible 2500")
                    break;
                case 5000:
                    asian_loop("Deductible 5000")
                    break;
                case 10000:
                    asian_loop("Deductible 10000")
                    break;
                default:
                    price = null;
            }
            break;
        case "Singapore":
            switch (deductible_value){
                case 2000:
                    asian_loop("Deductible 2500")
                    break;
                case 5000:
                    asian_loop("Deductible 5000")
                    break;
                case 10000:
                    asian_loop("Deductible 10000")
                    break;
                default:
                    price = null;
            }
            break;
    }
    return price;
}

function lookup_in_April_Thailand_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, paid_insurees_number, formula, hos_deductible){

    let price = 0;

    function Thailand_loop(deductible){
        for (var i = 0; i < paid_insurees_number; i++){
            // console.log(spreadsheet_rows[i][deductible])
            if ( spreadsheet_rows[i] ) {
                switch (coverage_types_included){
                    case "H":
                        formula = spreadsheet_rows[i][deductible];
                        break;
                    case "H+OD":
                        formula = spreadsheet_rows[i][deductible]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC":
                        formula = spreadsheet_rows[i][deductible]+spreadsheet_rows[i]["RC"];
                        break;
                    case "H+RC+OD":
                        formula = spreadsheet_rows[i][deductible]+spreadsheet_rows[i]["RC"]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC+M":
                        formula = spreadsheet_rows[i][deductible]+spreadsheet_rows[i]["RC"]+spreadsheet_rows[i]["MAT"];
                        break;
                    case "H+RC+OD+M":
                        formula = spreadsheet_rows[i][deductible]+spreadsheet_rows[i]["RC"]+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                        break;
                }
                price += formula;

            }

        }

    }

    // console.log(deductible_value)

    switch (deductible_value){
        case 500:
            Thailand_loop("HOS_deductible_500")
            break;
        case 1000:
            Thailand_loop("HOS_deductible_1000")
            break;
        case 2500:
            Thailand_loop("HOS_deductible_2500")
            break;
        case 5000:
            Thailand_loop("HOS_deductible_5000")
            break;
        case 10000:
            Thailand_loop("HOS_deductible_10000")
            break;
        default:
            price = null;
    }
    return price;
}

function lookup_in_April_HongKong_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, paid_insurees_number, formula, coinsurance_value){

    let price = 0;

    function Thailand_loop(deductible){
        for (var i = 0; i < paid_insurees_number; i++){
            let RC = spreadsheet_rows[i]["RC"];
            if (coinsurance_value === 80) {
                RC = Math.ceil(RC / 100 * 75);
            }
            if ( spreadsheet_rows[i] ) {
                switch (coverage_types_included){
                    case "H":
                        formula = spreadsheet_rows[i][deductible];
                        break;
                    case "H+OD":
                        formula = spreadsheet_rows[i][deductible]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC":
                        formula = spreadsheet_rows[i][deductible]+RC;
                        break;
                    case "H+RC+OD":
                        formula = spreadsheet_rows[i][deductible]+RC+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC+M":
                        formula = spreadsheet_rows[i][deductible]+RC+spreadsheet_rows[i]["MAT"];
                        break;
                    case "H+RC+OD+M":
                        formula = spreadsheet_rows[i][deductible]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                        break;
                }
                price += formula;

            }

        }

    }

    // console.log(deductible_value)

    switch (deductible_value){
        case 1500:
            Thailand_loop("HOS_deductible_1500")
            break;
        case 3000:
            Thailand_loop("HOS_deductible_3000")
            break;
        case 5000:
            Thailand_loop("HOS_deductible_5000")
            break;
        case 10000:
            Thailand_loop("HOS_deductible_10000")
            break;
        default:
            price = null;
    }
    return price;
}

function lookup_in_April_Singapur_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, paid_insurees_number, formula, coinsurance_value){

    let price = 0;

    function Thailand_loop(deductible){
        for (var i = 0; i < paid_insurees_number; i++){
            // console.log(spreadsheet_rows[i][deductible])
            let RC = spreadsheet_rows[i]["RC"] - spreadsheet_rows[i]["HOS"];
            if (coinsurance_value === 80) {
                RC = Math.ceil(RC / 100 * 75);
            }
            if ( spreadsheet_rows[i] ) {
                switch (coverage_types_included){
                    case "H":
                        formula = spreadsheet_rows[i][deductible];
                        break;
                    case "H+OD":
                        formula = spreadsheet_rows[i][deductible]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC":
                        formula = spreadsheet_rows[i][deductible]+RC;
                        break;
                    case "H+RC+OD":
                        formula = spreadsheet_rows[i][deductible]+RC+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC+M":
                        formula = spreadsheet_rows[i][deductible]+RC+spreadsheet_rows[i]["MAT"];
                        break;
                    case "H+RC+OD+M":
                        formula = spreadsheet_rows[i][deductible]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                        break;
                }
                price += formula;

            }

        }

    }

    // console.log(deductible_value)

    switch (deductible_value){
        case 2000:
            Thailand_loop("HOS_deductible_2000")
            break;
        case 5000:
            Thailand_loop("HOS_deductible_5000")
            break;
        case 10000:
            Thailand_loop("HOS_deductible_10000")
            break;
        default:
            price = null;
    }
    return price;
}

function default_April_calculation(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, deductible_value, coinsurance_value, destination_zone, currency, destination_country) {
    let coefficient; //a coefficient that takes the value of coinsurance or deductible to calculate the final price
    let price_repatriation; //a price of repatriation, here only basic
    let sum_prices = 0; // a variable for summed basic prices for premium
    let default_sum_prices;
    let total_sum;
    let fee = 20;
    let price_repatriation_number;

    // if there is deductible (only when CFE is not there), then the price will be changed based on deductible value, otherwise the coinsurance is taken
    if( deductible_value > 0 )
        coefficient = lookup_in_April_deductible_table(coverage_types_included, destination_zone, deductible_value);
    else
        coefficient = coinsurance_value;



    //we are paying for maximum 4 people (the 3rd and next kid is for free), but if its 'Zone 0', all kids will pay! :-)
    let paid_insurees_number
    if(destination_zone === 'Zone 0'){
        paid_insurees_number = insurees_number;
        price_repatriation_number = insurees_number;
    } else {
        paid_insurees_number = Math.min(insurees_number, 4);
        if(spreadsheet_rows[1]) {
            if (spreadsheet_rows[2]) {
                if(spreadsheet_rows[2]["Age"] >= 18){
                    price_repatriation_number = Math.min(insurees_number, 4);
                    paid_insurees_number = Math.min(insurees_number, 4);
                } else if (spreadsheet_rows[1]["Age"] < 18 && insurees_number >= 3) {
                    price_repatriation_number = Math.min(insurees_number, 3);
                } else if (spreadsheet_rows[1]["Age"] >= 18 && insurees_number >= 4) {
                    price_repatriation_number = Math.min(insurees_number, 4);
                } else {
                    price_repatriation_number = Math.min(insurees_number, 3);
                }
            } else if (spreadsheet_rows[1]["Age"] < 18 && insurees_number >= 3) {
                price_repatriation_number = Math.min(insurees_number, 3);
            }  else if (spreadsheet_rows[1]["Age"] >= 18 && insurees_number >= 4) {
                price_repatriation_number = Math.min(insurees_number, 4);
            }   else {
                price_repatriation_number = Math.min(insurees_number, 3);
            }
        } else {
            price_repatriation_number = Math.min(insurees_number, 3);
        }
    }



    // summing up the price all paid insurees
    //TODO: here we need to have spreadsheet_rows[i]["Tarif ANNUEL FDS TTC"] sorted from top (the oldest person)
    // to bottom (the youngest kid) to make sure we don't add up cheaper prices of children in the sum while leaving out
    // adults in the end.
    for (var i = 0; i < paid_insurees_number; i++){

        if ( spreadsheet_rows[i] ) {

            sum_prices += spreadsheet_rows[i]["Tarif ANNUEL FDS TTC"];

        }

    }

    console.log("paid_insurees_number " + price_repatriation_number)

    //not longer relevant: sum_prices = spreadsheet_rows.sum("Tarif ANNUEL FDS TTC", paid_insurees_number);
    price_repatriation = price_repatriation_number*145;

    // currency coefficient will be used for different currencies. by default April's price is coming from
    // pricing spreadsheets in EUR, we multiply it by coefficient 1 (fixed, provided by EA) to display price in USD
    // when destination country is USA and we want to show all plans in USD
    let currency_coefficient = 0;
    if (currency === 'USD') {
        //april's coefficient is quite strange and makes April really lose money on this, but ok, it's same price in USD as in eur for now
        currency_coefficient = 1;
    } else {
        //with EURO - don't apply coefficient since the price is already in euro from the spreadsheet
        currency_coefficient = 1;
    }

    if (payment_frequency === 12){
        fee = 0;
    }

    if (payment_frequency === 2){
        fee = fee*2;
    }

    if (payment_frequency === 1){
        fee = 0;
    }

    // console.log(currency_coefficient + " * " + coefficient + " * (" + sum_prices + ") / 100 + " + price_repatriation + ") / " + payment_frequency + " + " + fee);
    // total_sum = (currency_coefficient * coefficient*(sum_prices)/100+price_repatriation) + fee;
    total_sum = (((currency_coefficient * coefficient*(sum_prices)/100+price_repatriation) / payment_frequency)+fee ).toFixed(2);


    default_sum_prices = ((( currency_coefficient * 100 * (sum_prices)/100+price_repatriation) / payment_frequency)+fee).toFixed(2)+fee;


    return {"current_price":total_sum,"default_price":default_sum_prices};
}

function April_Thailande_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country) {
    let coefficient; //a coefficient that takes the value of coinsurance or deductible to calculate the final price
    let price_repatriation; //a price of repatriation, here only basic
    let sum_prices = 0; // a variable for summed basic prices for premium
    let default_total_sum = 0;
    // if there is deductible (only when CFE is not there), then the price will be changed based on deductible value, otherwise the coinsurance is taken
    let formula;
    let hos_deductible;
    //we are paying for maximum 4 people (the 3rd and next kid is for free)
    let paid_insurees_number = insurees_number;

    // summing up the price all paid insurees
    //TODO: here we need to have spreadsheet_rows[i]["Tarif ANNUEL FDS TTC"] sorted from top (the oldest person)
    // to bottom (the youngest kid) to make sure we don't add up cheaper prices of children in the sum while leaving out
    // adults in the end.

    for (var i = 0; i < paid_insurees_number; i++){
        let RC = spreadsheet_rows[i]["RC"];
        if ( spreadsheet_rows[i] ) {
            switch (coverage_types_included){
                case "H":
                    formula = spreadsheet_rows[i]["HOS"];
                    break;
                case "H+OD":
                    formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC":
                    formula = spreadsheet_rows[i]["HOS"]+RC;
                    break;
                case "H+RC+OD":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                    break;
                case "H+RC+OD+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                    break;
            }
            default_total_sum += formula;

        }

    }

    if( deductible_value > 0 )
        sum_prices = lookup_in_April_Thailand_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, paid_insurees_number, formula, hos_deductible);
    else
    {
        for (var i = 0; i < paid_insurees_number; i++){
            let RC = spreadsheet_rows[i]["RC"];
            if (coinsurance_value === 80) {
                RC = RC / 100 * 90;
            }
            if ( spreadsheet_rows[i] ) {
                switch (coverage_types_included){
                    case "H":
                        formula = spreadsheet_rows[i]["HOS"];
                        break;
                    case "H+OD":
                        formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC":
                        formula = spreadsheet_rows[i]["HOS"]+RC;
                        break;
                    case "H+RC+OD":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                        break;
                    case "H+RC+OD+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                        break;
                }
                sum_prices += formula;

            }

        }
    }


    //not longer relevant: sum_prices = spreadsheet_rows.sum("Tarif ANNUEL FDS TTC", paid_insurees_number);
    price_repatriation = paid_insurees_number*124;

    // currency coefficient will be used for different currencies. by default April's price is coming from
    // pricing spreadsheets in EUR, we multiply it by coefficient 1 (fixed, provided by EA) to display price in USD
    // when destination country is USA and we want to show all plans in USD
    let currency_coefficient = 1;

    total_sum = sum_prices / currency_coefficient;
    default_total_sum = default_total_sum / currency_coefficient;
    switch (insurees_number){
        case 2:
            total_sum = total_sum / 100 * 95;
            default_total_sum = default_total_sum / 100 * 95;
            break;
        case 3:
            total_sum = total_sum / 100 * 92.5;
            default_total_sum = default_total_sum / 100 * 92.5;
            break;
        case 4:
            total_sum = total_sum / 100 * 90;
            default_total_sum = default_total_sum / 100 * 90;
            break;
        case 5:
            total_sum = total_sum / 100 * 85;
            default_total_sum = default_total_sum / 100 * 85;
            break;
        default:
            break;
    }
    let stampDuty = 0.004;

    total_sum = Math.round(total_sum + total_sum * stampDuty);
    default_total_sum = Math.round(default_total_sum + default_total_sum * stampDuty);

    return {"current_price":total_sum,"default_price":default_total_sum};
}

function April_Singapur_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country) {
    let coefficient; //a coefficient that takes the value of coinsurance or deductible to calculate the final price
    let price_repatriation; //a price of repatriation, here only basic
    let sum_prices = 0; // a variable for summed basic prices for premium
    let default_total_sum = 0;
    // if there is deductible (only when CFE is not there), then the price will be changed based on deductible value, otherwise the coinsurance is taken
    let formula;
    let hos_deductible;
    //we are paying for maximum 4 people (the 3rd and next kid is for free)
    let paid_insurees_number = insurees_number;

    // summing up the price all paid insurees
    //TODO: here we need to have spreadsheet_rows[i]["Tarif ANNUEL FDS TTC"] sorted from top (the oldest person)
    // to bottom (the youngest kid) to make sure we don't add up cheaper prices of children in the sum while leaving out
    // adults in the end.

    for (var i = 0; i < paid_insurees_number; i++){
        let RC = spreadsheet_rows[i]["RC"];
        if ( spreadsheet_rows[i] ) {
            switch (coverage_types_included){
                case "H":
                    formula = spreadsheet_rows[i]["HOS"];
                    break;
                case "H+OD":
                    formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC":
                    formula = spreadsheet_rows[i]["HOS"]+RC;
                    break;
                case "H+RC+OD":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                    break;
                case "H+RC+OD+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                    break;
            }
            default_total_sum += formula;

        }

    }

    if( deductible_value > 0 )
        sum_prices = lookup_in_April_Singapur_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, paid_insurees_number, formula, coinsurance_value);
    else
    {
        for (var i = 0; i < paid_insurees_number; i++) {
            let RC = spreadsheet_rows[i]["RC"] - spreadsheet_rows[i]["HOS"];
            if (coinsurance_value === 80) {
                RC = RC / 100 * 75;
            }

            if ( spreadsheet_rows[i] ) {
                switch (coverage_types_included){
                    case "H":
                        formula = spreadsheet_rows[i]["HOS"];
                        break;
                    case "H+OD":
                        formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC":
                        formula = spreadsheet_rows[i]["HOS"]+RC;
                        break;
                    case "H+RC+OD":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                        break;
                    case "H+RC+OD+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                        break;
                }
                sum_prices += formula;

            }

        }
    }


    //not longer relevant: sum_prices = spreadsheet_rows.sum("Tarif ANNUEL FDS TTC", paid_insurees_number);
    price_repatriation = paid_insurees_number*124;

    // currency coefficient will be used for different currencies. by default April's price is coming from
    // pricing spreadsheets in EUR, we multiply it by coefficient 1 (fixed, provided by EA) to display price in USD
    // when destination country is USA and we want to show all plans in USD
    let currency_coefficient = 0.74;

    total_sum = sum_prices * currency_coefficient;
    default_total_sum = default_total_sum / currency_coefficient;
    switch (insurees_number){
        case 2:
            total_sum = total_sum / 100 * 95;
            default_total_sum = default_total_sum / 100 * 95;
            break;
        case 3:
            total_sum = total_sum / 100 * 92.5;
            default_total_sum = default_total_sum / 100 * 92.5;
            break;
        case 4:
            total_sum = total_sum / 100 * 90;
            default_total_sum = default_total_sum / 100 * 90;
            break;
        case 5:
            total_sum = total_sum / 100 * 85;
            default_total_sum = default_total_sum / 100 * 85;
            break;
        default:

    }

    total_sum = total_sum + (total_sum / 100 * 7);
    default_total_sum = default_total_sum + (default_total_sum / 100 * 7);

    return {"current_price":total_sum,"default_price":default_total_sum};
}

function April_Vietnam_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country, payment_frequency) {
    let sum_prices = 0; // a variable for summed basic prices for premium
    let default_total_sum = 0;
    // if there is deductible (only when CFE is not there), then the price will be changed based on deductible value, otherwise the coinsurance is taken
    let formula;
    let hos_deductible;

    for (var i = 0; i < insurees_number; i++){
        let RC = spreadsheet_rows[i]["RC"];
        if ( spreadsheet_rows[i] ) {
            switch (coverage_types_included){
                case "H":
                    formula = spreadsheet_rows[i]["HOS"];
                    break;
                case "H+OD":
                    formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC":
                    formula = spreadsheet_rows[i]["HOS"]+RC;
                    break;
                case "H+RC+OD":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                    break;
                case "H+RC+OD+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                    break;
            }
            default_total_sum += formula;

        }

    }


    if( deductible_value > 0 )
        sum_prices = lookup_in_April_Thailand_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, insurees_number, formula, hos_deductible);
    else
    {
        for (var i = 0; i < insurees_number; i++){
            let RC = spreadsheet_rows[i]["RC"];
            if ( spreadsheet_rows[i] ) {
                switch (coverage_types_included){
                    case "H":
                        formula = spreadsheet_rows[i]["HOS"];
                        break;
                    case "H+OD":
                        formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC":
                        formula = spreadsheet_rows[i]["HOS"]+RC;
                        break;
                    case "H+RC+OD":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                        break;
                    case "H+RC+OD+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                        break;
                }
                sum_prices += formula;

            }

        }
    }


    // currency coefficient will be used for different currencies. by default April's price is coming from
    // pricing spreadsheets in EUR, we multiply it by coefficient 1 (fixed, provided by EA) to display price in USD
    // when destination country is USA and we want to show all plans in USD
    let currency_coefficient = 1;

    total_sum = sum_prices / currency_coefficient;
    if (payment_frequency === 1) {
        switch (insurees_number){
            case 2:
                total_sum = total_sum / 100 * 95;
                default_total_sum = default_total_sum / 100 * 95;
                break;
            case 3:
                total_sum = total_sum / 100 * 92.5;
                default_total_sum = default_total_sum / 100 * 92.5;
                break;
            case 4:
                total_sum = total_sum / 100 * 90;
                default_total_sum = default_total_sum / 100 * 90;
                break;
            case 5:
                total_sum = total_sum / 100 * 85;
                default_total_sum = default_total_sum / 100 * 85;
                break;
            default:

        }
    } else {
        total_sum = total_sum + (total_sum / 100 * 5);
        default_total_sum = default_total_sum + (default_total_sum / 100 * 5);
    }

    console.log(total_sum)
    console.log(default_total_sum)

    return {"current_price":total_sum,"default_price":default_total_sum};
}

function April_HongKong_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country, payment_frequency) {

    let coefficient; //a coefficient that takes the value of coinsurance or deductible to calculate the final price
    let price_repatriation; //a price of repatriation, here only basic
    let sum_prices = 0; // a variable for summed basic prices for premium
    let default_total_sum = 0;
    // if there is deductible (only when CFE is not there), then the price will be changed based on deductible value, otherwise the coinsurance is taken
    let formula;
    let hos_deductible;
    //we are paying for maximum 4 people (the 3rd and next kid is for free)
    let paid_insurees_number = insurees_number;

    // summing up the price all paid insurees
    //TODO: here we need to have spreadsheet_rows[i]["Tarif ANNUEL FDS TTC"] sorted from top (the oldest person)
    // to bottom (the youngest kid) to make sure we don't add up cheaper prices of children in the sum while leaving out
    // adults in the end.

    for (var i = 0; i < paid_insurees_number; i++){
        let RC = spreadsheet_rows[i]["RC"];
        if ( spreadsheet_rows[i] ) {
            switch (coverage_types_included){
                case "H":
                    formula = spreadsheet_rows[i]["HOS"];
                    break;
                case "H+OD":
                    formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC":
                    formula = spreadsheet_rows[i]["HOS"]+RC;
                    break;
                case "H+RC+OD":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                    break;
                case "H+RC+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                    break;
                case "H+RC+OD+M":
                    formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                    break;
            }
            default_total_sum += formula;

        }

    }

    if( deductible_value > 0 )
        sum_prices = lookup_in_April_HongKong_deductible_table(spreadsheet_rows, coverage_types_included, destination_zone, deductible_value, destination_country, paid_insurees_number, formula, coinsurance_value);
    else
    {
        for (var i = 0; i < paid_insurees_number; i++){
            let RC = spreadsheet_rows[i]["RC"];
            if (coinsurance_value === 80) {
                RC = Math.ceil(RC / 100 * 75);
            }
            if ( spreadsheet_rows[i] ) {
                switch (coverage_types_included){
                    case "H":
                        formula = spreadsheet_rows[i]["HOS"];
                        break;
                    case "H+OD":
                        formula = spreadsheet_rows[i]["HOS"]+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC":
                        formula = spreadsheet_rows[i]["HOS"]+RC;
                        break;
                    case "H+RC+OD":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"];
                        break;
                    case "H+RC+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["MAT"];
                        break;
                    case "H+RC+OD+M":
                        formula = spreadsheet_rows[i]["HOS"]+RC+spreadsheet_rows[i]["OD"]+spreadsheet_rows[i]["MAT"];
                        break;
                }
                sum_prices += formula;

            }

        }
    }


    //not longer relevant: sum_prices = spreadsheet_rows.sum("Tarif ANNUEL FDS TTC", paid_insurees_number);
    price_repatriation = paid_insurees_number*124;

    // currency coefficient will be used for different currencies. by default April's price is coming from
    // pricing spreadsheets in EUR, we multiply it by coefficient 1 (fixed, provided by EA) to display price in USD
    // when destination country is USA and we want to show all plans in USD
    let currency_coefficient = 1;

    total_sum = sum_prices / currency_coefficient;
    if (payment_frequency === 1) {
        switch (insurees_number){
            case 2:
                total_sum = total_sum / 100 * 95;
                default_total_sum = default_total_sum / 100 * 95;
                break;
            case 3:
                total_sum = total_sum / 100 * 92.5;
                default_total_sum = default_total_sum / 100 * 92.5;
                break;
            case 4:
                total_sum = total_sum / 100 * 90;
                default_total_sum = default_total_sum / 100 * 90;
                break;
            case 5:
                total_sum = total_sum / 100 * 85;
                default_total_sum = default_total_sum / 100 * 85;
                break;
        }
    } else {
        total_sum = total_sum;
        default_total_sum = default_total_sum
    }

    return {"current_price":total_sum,"default_price":default_total_sum};
}


function calculateFinalPriceApril(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, deductible_value, coinsurance_value, destination_zone, currency, destination_country) {

    // let total_sum = default_April_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, payment_frequency);
    let total_sum;

    let currency_coefficient = 1;
    let thb_currency_coefficient = 1;

    if (currency === 'USD') {
        //april's coefficient is quite strange and makes April really lose money on this, but ok, it's same price in USD as in eur for now
        currency_coefficient = 1;
        thb_currency_coefficient = 31.44;
    } else {
        //with EURO - don't apply coefficient since the price is already in euro from the spreadsheet
        currency_coefficient = 0.81;
        thb_currency_coefficient = 37.44;
    }

    switch (destination_country){
        case "Thaïlande":
            total_sum = April_Thailande_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country);
            total_sum["current_price"] = ((total_sum["current_price"] / thb_currency_coefficient)/ payment_frequency).toFixed(2);
            total_sum["default_price"] = ((total_sum["default_price"] * thb_currency_coefficient) / payment_frequency).toFixed(2);
            return {"current_price":total_sum["current_price"],"default_price":total_sum["default_price"]};
        case "Vietnam":
        case "Laos":
        case "Cambodge":
        case "Myanmar":
            total_sum = April_Vietnam_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country, payment_frequency);
            total_sum["current_price"] = ((total_sum["current_price"] * currency_coefficient) / payment_frequency).toFixed(2);
            total_sum["default_price"] = ((total_sum["default_price"] * currency_coefficient) / payment_frequency).toFixed(2);
            return {"current_price":total_sum["current_price"],"default_price":total_sum["default_price"]};
        case "Singapour":
            total_sum = April_Singapur_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country);
            total_sum["current_price"] = ((total_sum["current_price"] / currency_coefficient)/ payment_frequency).toFixed(2);
            total_sum["default_price"] = ((total_sum["default_price"] * currency_coefficient) / payment_frequency).toFixed(2);
            return {"current_price":total_sum["current_price"],"default_price":total_sum["default_price"]};
        case "Hong Kong":
        case "Chine":
        case "Japon":
        case "Australie":
        case "Nouvelle-Zélande":
        case "Corée du Sud":
        case "Taïwan":
        case "Malaisie":
            total_sum = April_HongKong_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, total_sum, destination_country, payment_frequency);
            let percentage_increase;
            switch (payment_frequency){
                case 1:
                    percentage_increase = 1;
                    break;
                case 2:
                    percentage_increase = 1.04;
                    break;
                case 4:
                    percentage_increase = 1.05;
                    break;
                case 12:
                    percentage_increase = 1;
                    break;
            }
            total_sum["current_price"] = (((total_sum["current_price"] / currency_coefficient) * percentage_increase)/ payment_frequency).toFixed(2);
            total_sum["default_price"] = (((total_sum["default_price"] * currency_coefficient) * percentage_increase) / payment_frequency).toFixed(2);
            return {"current_price":total_sum["current_price"],"default_price":total_sum["default_price"]};
        default:
            // total_sum = default_April_calculation(deductible_value, coverage_types_included, destination_zone, coinsurance_value, insurees_number, spreadsheet_rows, currency, payment_frequency);
            // return (total_sum / payment_frequency).toFixed(2);
            return null;
    }
    return total_sum.toFixed(2);
}

function calculateFinalPriceAllianz(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, coinsurance_value, currency) {

    var price_outpatient = 0; // declaring variable, price will be based on selected by user outpatient (coinsurance) option
    var sum_prices = 0; // a variable for summed basic prices for premium
    let default_sum_prices = 0;
    var dental_price = 0;
    var basic_price;
    var total_sum;
    var default_total_sum;
    var outpatient_index; // based on the coinsurance value; 100% -> 1, 90% ->2, 80% ->3; in order to take the right price from the row
    let defoult_price_outpatient = 0; // based on the coinsurance value; 100% -> 1, 90% ->2, 80% ->3; in order to take the right price from the row

    if(coinsurance_value === 100)
        outpatient_index = 1;
    else if (coinsurance_value === 90)
        outpatient_index = 2;
    else
        outpatient_index = 3;


    for (var i = 0; i < insurees_number; i++){
        basic_price = spreadsheet_rows[i][0];

        //we only add up outpatient price IF the plan doesn't include RC
        //only H+M plans don't include RC in Allianz - All1, All3, that's why we only set price_outpatient
        // for all the other plans but not these two with this condition
        if (coverage_types_included !== 'H+M'){
            price_outpatient = spreadsheet_rows[i][outpatient_index];
            defoult_price_outpatient = spreadsheet_rows[i][1];
        }
        if (coverage_types_included === "H+RC+OD" || coverage_types_included === "H+RC+OD+M" ){
            dental_price = spreadsheet_rows[i][4];
        }

        sum_prices += basic_price + price_outpatient + dental_price;
        default_sum_prices += basic_price + defoult_price_outpatient + dental_price;
    }

    // currency coefficient will be used for different currencies. by default Allianz's price is coming from
    // pricing spreadsheets in EUR, we multiply it by coefficient (fixed, provided by EA) to display price in USD
    // when destination country is USA and we want to show all plans in USD
    let currency_coefficient = 0;
    if (currency === 'USD') {
        currency_coefficient = 1.2;
    } else {
        //with EURO - don't apply coefficient since the price is already in euro from the spreadsheet
        currency_coefficient = 1;
    }
    // console.log("Allianz price before currency coefficient: " + sum_prices + " EUR");
    sum_prices *= currency_coefficient;
    default_sum_prices *= currency_coefficient;
    // console.log("Allianz price after currency coefficient: " + sum_prices + " USD");

    switch (payment_frequency){
        case 12:
            total_sum = 1.05*sum_prices;
            default_total_sum = 1.05*default_sum_prices;
            break;
        case 4:
            total_sum = 1.04*sum_prices;
            default_total_sum = 1.04*default_sum_prices;
            break;
        case 2:
            total_sum = 1.03 * sum_prices;
            default_total_sum = 1.03 * default_sum_prices;
            break;
        default: //yearly price
            total_sum = sum_prices;
            default_total_sum = default_sum_prices;
    }

    total_sum = (total_sum / payment_frequency).toFixed(2)
    default_total_sum = (default_total_sum / payment_frequency).toFixed(2)

    return {"current_price":total_sum,"default_price":default_total_sum};
}

function calculateFinalPriceMSH(payment_frequency, insurees_number, spreadsheet_rows, deductible_value, currency, coverage_types_included) {
    var price_liability; //a price of liability, always included in the price
    var sum_prices = 0; // a variable for summed basic prices for premium
    let default_sum_prices = 0;

    //since alasql doesn't work with "0" value in excel sheets we needed to rename it to "null", therefore in resuling spreadsheet_rows
    // it will also return as null, so we're replacing the value here to be able to correctly query for it
    if (deductible_value === 0) {
        deductible_value = "null";
    } else if (deductible_value === 500){
        deductible_value = 350;
    } else if (deductible_value === 1000){
        deductible_value = 750;
    }


    // we are paying for maximum 4 people (the 3rd and next kid is for free)
    var paid_insurees_number = Math.min(insurees_number, 4);
    for (var i = 0; i < paid_insurees_number; i++){
        sum_prices += spreadsheet_rows[i][deductible_value];
    }

    for (var i = 0; i < paid_insurees_number; i++){
        default_sum_prices += spreadsheet_rows[i]["null"];
    }

    const priceConfig = {
        '1': {
            'USD': { 'default': 147.52, 'H': 100.52 },
            'EUR': { 'default': 127.57, 'H': 83.57 }
        },
        'default': {
            'USD': { 'default': 204.42, 'H': 150.42 },
            'EUR': { 'default': 176.98, 'H': 125.98 }
        }
    };

    const currencyKey = currency === 'USD' ? 'USD' : 'EUR';
    const insureesKey = insurees_number === 1 ? '1' : 'default';
    const coverageKey = coverage_types_included === 'H' ? 'H' : 'default';

    price_liability = priceConfig[insureesKey][currencyKey][coverageKey];

    let currency_coefficient = 1;
    if (currency === 'USD') {
        //april's coefficient is quite strange and makes April really lose money on this, but ok, it's same price in USD as in eur for now
        currency_coefficient = 1.25;
    }



    var total_sum = (((sum_prices*currency_coefficient) + price_liability  ) /payment_frequency).toFixed(2);
    default_sum_prices = (((default_sum_prices*currency_coefficient) + price_liability) /payment_frequency).toFixed(2);

    return {"current_price":total_sum,"default_price":default_sum_prices};

}

function calculateFinalPriceHenner(payment_frequency, insurees_number, spreadsheet_rows, company_guarantee_level, currency) {
    let formule = company_guarantee_level;
    //since formule is saved in boilerplate plans with dash, replace it with space to access the value in an array
    //returning from the backend

    formule = formule.replace("-", ' ');

    var sum_prices = 0; // a variable for summed basic prices for premium

    // different way of counting for 1-2, >3 people
    if(insurees_number <3){
        for(var i=0; i<insurees_number; i++)
            sum_prices += spreadsheet_rows[i][formule];
    }else{
        //in case if there are >3 people back-end will return only one spreadsheet row that will be the price for all the people together
        sum_prices = spreadsheet_rows[0][formule];
    }


    let currency_coefficient = 0;
    if (currency === 'USD') {
        //april's coefficient is quite strange and makes April really lose money on this, but ok, it's same price in USD as in eur for now
        currency_coefficient = 1.2;
    } else {
        //with EURO - don't apply coefficient since the price is already in euro from the spreadsheet
        currency_coefficient = 1;
    }

    //the prices are quarterly, therefore we multiply them by 4 for an yearly price
    var total_sum = ((4*sum_prices) * currency_coefficient)/payment_frequency;

    return {"current_price":total_sum,"default_price":total_sum};
}

function lookup_in_AssurTravel_deductible_table(deductible_value, insurees_number){
    let row;

    if (insurees_number === 2 && deductible_value === 300) {
        return "Franchise1";
    } else if (insurees_number === 1 && deductible_value === 300) {
        return "Franchise2";
    }

    switch (true) {
        case (deductible_value === 150):
            row = "Franchise1";
            break;
        case (deductible_value === 450):
            row = "Franchise1";
            break;
        case (deductible_value === 600):
            row = "Franchise2";
            break;
        case (deductible_value === 900):
            row = "Franchise2";
            break;
        case (deductible_value === 0):
            row = "Price";
            break;
    }

    return row
}

function calculateFinalPriceAssurTravel(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, company_guarantee_level, deductible_value, currency) {

    // console.log("Basic price Assur Travel Insurees number", insurees_number)
    var sum_prices = 0; // a variable for summed basic prices for premium
    let default_sum_prices = 0;
    let month_count = 0;
    let fee = 23;
    switch (payment_frequency) {
        case 1:
            month_count = 12;
            break;
        case 2:
            month_count = 6;
            break;
        case 4:
            month_count = 3;
            break;
        case 12:
            month_count = 1;
            break;
        default:
            month_count = "error"
            break;
    }

    let row = lookup_in_AssurTravel_deductible_table(deductible_value, insurees_number)

    if(insurees_number <= 2){
        for(let i=0; i<insurees_number; i++) {
            sum_prices = sum_prices + spreadsheet_rows[i][row]
            default_sum_prices = default_sum_prices + spreadsheet_rows[i]["Price"];
        }
    }else{
        //in case if there are >3 people back-end will return only one spreadsheet row that will be the price for all the people together
        sum_prices = spreadsheet_rows[0][row]
        default_sum_prices =  default_sum_prices + spreadsheet_rows[0]["Price"];
    }

    let currency_coefficient = 0;
    if (currency === 'USD') {
        //april's coefficient is quite strange and makes April really lose money on this, but ok, it's same price in USD as in eur for now
        currency_coefficient = 1.09;
    } else {
        //with EURO - don't apply coefficient since the price is already in euro from the spreadsheet
        currency_coefficient = 1;
    }

    // different way of counting for 1-2, >3 people
    //         sum_prices = spreadsheet_rows[0]['Price']
    fee = fee / payment_frequency;
    // console.log(fee)
    var total_sum = (sum_prices * month_count + fee) * currency_coefficient;
    default_sum_prices = (default_sum_prices * month_count + fee) * currency_coefficient;

    return {"current_price":total_sum,"default_price":default_sum_prices};
}


function calculateFinalPriceCigna(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, deductible_value, destination_zone, currency) {
    let i;
    let coefficient; //a coefficient that takes the value of coinsurance or deductible to calculate the final price
    let price_repatriation; //a price of repatriation, here only basic
    let sum_prices = 0; // a variable for summed basic prices for premium
    let default_total_sum = 0;
    let total_sum;

    //we are paying for maximum 4 people (the 3rd and next kid is for free)
    let paid_insurees_number = insurees_number;

    // console.log(deductible_value)
    // console.log(currency)

    let new_deductible_value;

    if (currency != 'USD') {
        switch (deductible_value) {
            case 0:
                new_deductible_value = 0;
                break;
            case 275:
                new_deductible_value = 375;
                break;
            case 550:
                new_deductible_value = 750;
                break;
            case 1100:
                new_deductible_value = 1500;
                break;
            default:
                new_deductible_value = 0;
                break;
        }

        // deductible_value = [0, 375, 750, 1500];
    } else {
        new_deductible_value = deductible_value;
    }

    // console.log(new_deductible_value)

    // if there is deductible (only when CFE is not there), then the price will be changed based on deductible value, otherwise the coinsurance is taken

    // console.log("deductible_value",deductible_value)

    // summing up the price all paid insurees
    for (i = 0; i < paid_insurees_number; i++){

        if ( spreadsheet_rows[i] ) {

            sum_prices += spreadsheet_rows[i][new_deductible_value];
            default_total_sum += spreadsheet_rows[i][0];

        }

    }

    // currency coefficient will be used for different currencies. by default April's price is coming from
    // pricing spreadsheets in EUR, we multiply it by coefficient 1 (fixed, provided by EA) to display price in USD
    // when destination country is USA and we want to show all plans in USD
    let currency_coefficient = 0;
    if (currency === 'USD') {
        //april's coefficient is quite strange and makes April really lose money on this, but ok, it's same price in USD as in eur for now
        currency_coefficient = 1;
    } else {
        //with EURO - don't apply coefficient since the price is already in euro from the spreadsheet
        currency_coefficient = 0.86;
    }

    total_sum = currency_coefficient * sum_prices;
    default_total_sum = currency_coefficient * default_total_sum;

    if(payment_frequency === 2){
        total_sum = total_sum + (total_sum/100 * 15.4);
        default_total_sum = default_total_sum + (default_total_sum/100 * 15.4);
    }

    if(payment_frequency === 4){
        total_sum = total_sum + (total_sum/100 * 7.7);
        default_total_sum = default_total_sum + (default_total_sum/100 * 7.7);
    }

    if(payment_frequency === 12){
        total_sum = total_sum + (total_sum/100 * 11.11);
        default_total_sum = default_total_sum + (default_total_sum/100 * 11.11);
    }

    default_total_sum = (default_total_sum/ payment_frequency).toFixed(2)
    total_sum = (total_sum/ payment_frequency).toFixed(2)

    return {"current_price":total_sum,"default_price":default_total_sum}
}


export function calculateFinalPrice(insurance_company, payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, deductible_value, coinsurance_value, destination_zone, company_guarantee_level, currency, destination_country) {
    switch (insurance_company) {

        case 'Cigna':
            return calculateFinalPriceCigna(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, deductible_value, destination_zone, currency);

        case 'April':
            // return 1000;
            return default_April_calculation(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, deductible_value, coinsurance_value, destination_zone, currency, destination_country);
        case 'Liberty':
        case 'PTI':
            // return 1000;
            return calculateFinalPriceApril(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, deductible_value, coinsurance_value, destination_zone, currency, destination_country);

        case 'Allianz':
            return calculateFinalPriceAllianz(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included,coinsurance_value, currency);

        case 'MSH':
            return calculateFinalPriceMSH(payment_frequency, insurees_number, spreadsheet_rows, deductible_value, currency, coverage_types_included);

        case 'Henner':
            return calculateFinalPriceHenner(payment_frequency, insurees_number, spreadsheet_rows, company_guarantee_level, currency);

        case 'Assur Travel':
            // return 1
            return calculateFinalPriceAssurTravel(payment_frequency, insurees_number, spreadsheet_rows, coverage_types_included, company_guarantee_level, deductible_value, currency);

        default:
            return "error";
    }

}

export function sendToTelegram(user_info) {

    fetch(`https://api.telegram.org/bot5180455823:AAGBoxbz7OD52APTYJ75Wq-A_F4YMUYrn4k/sendMessage?chat_id=-1001675808141&parse_mode=html&text=${JSON.stringify({user_info})}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user_info
        })

    })

}



